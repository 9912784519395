'use strict';

import store from 'store';
import moment from 'moment';
import uuid from 'uuid';
import platform from 'platform';
import cookie from 'cookie-monster';

import AuthStore from '../stores/AuthStore';
import { serializeTestGroups } from './ABTest';
import { getConfig, getMref } from './Env';
import SessionRecorder from './SessionRecorder';

const LOCALSTORAGE_KEY = 'analytics';

const _store = {
    distinct_id: false,
};

const _sessionId = {
    search: null,
};

function saveToLocal() {
    store.set(LOCALSTORAGE_KEY, _store);

    cookie.setItem(LOCALSTORAGE_KEY, _store.distinct_id, {
        expires: moment().add(10, 'years').toDate().toUTCString(),
        domain: getConfig('www_host').replace('www.', '.'),
        path: '/',
        secure: true,
    });
}

const analytics = {
    track: (event, properties = {}, sendUTMParams = false) => {
        const mvars = store.get('m_var') || {};
        const mref = getMref();
        let visit_utm_params = {};

        const testGroups = serializeTestGroups();

        if (testGroups.length > 0) {
            properties['Test Groups'] = testGroups;
        }

        if (mvars.sref) {
            properties['Shared From ID'] = mvars.sref;
        }

        if (sendUTMParams) {
            visit_utm_params = {
                utm_campaign: mvars.campaign,
                utm_source: mvars.source,
                utm_medium: mvars.medium,
                utm_content: mvars.content,
            };
        }

        if (mref) {
            properties['Merchant Referral'] = mref;
        }

        const searchInfo = _.info.searchInfo(document.referrer);
        if (searchInfo) {
            properties['search_engine'] = searchInfo['search_engine'];
            properties['search_keywords'] = searchInfo['search_keywords'];
        }

        const device = _.info.device(navigator.userAgent);
        if (device) {
            properties['device'] = device;
        }

        const deviceId = cookie.getItem('device-id');
        if (deviceId) {
            properties['device_id'] = deviceId;
        }

        const logRocketSessionUrl = SessionRecorder.getSessionURL();

        if (logRocketSessionUrl) {
            properties['logrocket_session_url'] = logRocketSessionUrl;
        }

        const payload = {
            distinct_id: _store.distinct_id,
            session_id: getConfig("instance_id"),
            initial_referrer: _store.initial_referrer,
            referrer: document.referrer,
            event,
            browser: platform.name,
            browser_version: platform.version,
            current_url: document.location.href,
            os: platform.os.family,
            screen_height: screen && screen.height,
            screen_width: screen && screen.width,
            build_number: getConfig('build_number'),
            properties,
            visit_utm_params,
        };

        // Analytics calls are fire and forget
        return AuthStore.fetch(
            getConfig('users_api') + '/track',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; schema=track/event/1' },
                body: JSON.stringify(payload),
            },
            true
        ).then(
            (success) => false,
            (error) => false
        );
    },
};

const _ = {
    info: {
        searchInfo: (referrer) => {
            const search = _.info.searchEngine(referrer);
            const param = search != 'yahoo' ? 'q' : 'p';
            let ret = [];

            if (search !== null) {
                ret['search_engine'] = search;

                const keyword = _.getQueryParam(referrer, param);
                if (keyword.length) {
                    ret['search_keywords'] = keyword;
                }
            }

            return ret;
        },

        searchEngine: (referrer) => {
            if (referrer.search('https?://(.*)google.([^/?]*)') === 0) return 'google';
            if (referrer.search('https?://(.*)bing.com') === 0) return 'bing';
            if (referrer.search('https?://(.*)yahoo.com') === 0) return 'yahoo';
            if (referrer.search('https?://(.*)duckduckgo.com') === 0) return 'duckduckgo';

            return null;
        },

        device: (user_agent) => {
            if (/iPad/.test(user_agent)) return 'iPad';
            if (/iPod/.test(user_agent)) return 'iPod Touch';
            if (/iPhone/.test(user_agent)) return 'iPhone';
            if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) return 'BlackBerry';
            if (/Windows Phone/i.test(user_agent)) return 'Windows Phone';
            if (/Android/.test(user_agent)) return 'Android';

            return null;
        },
    },

    getQueryParam: (url, param) => {
        param = param.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regexS = '[\\?&]' + param + '=([^&#]*)';
        const regex = new RegExp(regexS);
        const results = regex.exec(url);

        if (results === null || (results && typeof results[1] !== 'string' && results[1].length)) {
            return '';
        }

        return decodeURIComponent(results[1]).replace(/\+/g, ' ');
    },
};

function reset(initial_referrer = null) {
    _store.distinct_id = uuid.v4();
    _store.initial_referrer = initial_referrer;

    saveToLocal();
}

export default {
    initialize() {
        let { distinct_id, initial_referrer } = store.get(LOCALSTORAGE_KEY) || {};

        _store.distinct_id = distinct_id || cookie.getItem(LOCALSTORAGE_KEY) || uuid.v4();
        _store.initial_referrer = initial_referrer || document.referrer;

        saveToLocal();
    },

    getDistinctId() {
        return _store.distinct_id;
    },

    getInitialReferrer() {
        return _store.initial_referrer;
    },

    getVisitUtmParams() {
        return store.get('m_var') || {};
    },

    identify(user) {
        _store.anon_id = _store.distinct_id;
        _store.distinct_id = user.distinct_id || user.uuid;
        _store.initial_referrer = user.initial_referrer;

        saveToLocal();
    },

    launchApp(isNewCampaign) {
        analytics.track('App Launched', {}, isNewCampaign);
    },

    reloadForUpgrade(properties) {
        return analytics.track('Reload For New Version', properties);
    },

    trackUncaughtException(error, errorInfo) {
        const ignoreTheseErrors = ["The input element's type ('number') does not support selection."];

        if (ignoreTheseErrors.includes(error.message)) {
            console.log({ f: 'analytics.trackUncaughtException inhibit', error });
            return;
        }

        analytics.track('Uncaught Exception', {
            Message: error.message,
            Stack: error.stack,
            'Component Stack': errorInfo?.componentStack,
        });
    },

    trackChatException(exception) {
        analytics.track('Chat Exception', exception);
    },

    startSignIn() {
        analytics.track('Start Sign In');
    },

    startSignUp() {
        analytics.track('Start Sign Up');
    },

    openReferralModal() {
        analytics.track('Rewards Referral Landing Page');
    },

    referralSignUp() {
        analytics.track('Referral Sign Up Clicked');
    },

    referralModalX() {
        analytics.track('Dismiss Rewards Referral Landing Page');
    },

    referralModalButton() {
        analytics.track('Press Sign Up Button On Rewards Referral Landing Page');
    },

    register(properties = {}) {
        analytics.track('Register', { ...properties, anon_id: _store.anon_id });
    },

    registerError(properties = {}) {
        analytics.track('Register Error', properties);
    },

    login() {
        analytics.track('Login', { anon_id: _store.anon_id }, true);
    },

    loginError(properties = {}) {
        analytics.track('Login Error', properties);
    },

    logout() {
        analytics.track('Logout');
        // Make sure the identity and distinct_id are reset
        reset();
    },

    favoritesSearch(terms, filters, sort, properties = {}) {
        this._eventWithTerms('Favorites Search', terms, filters, sort, properties);
    },

    swapSearch(terms, filters, sort, sessionSearchId, properties = {}) {
        _sessionId.search = sessionSearchId;
        this._eventWithTerms('Swap Search', terms, filters, sort, {
            ...properties,
            'Search Session ID': _sessionId.search,
        });
    },

    /**
     * Search / Add / Swap Events
     */
    globalSearch(terms, filters, sort, properties = {}) {
        this._eventWithTerms('Global Search', terms, filters, sort, properties);
    },

    _eventWithTerms(eventName, terms, filters, sort, properties = {}) {
        // Convert filters to something usable
        let aFilters = {};

        if (filters.library) {
            aFilters.Library = true;
        }

        if (filters.tags) {
            aFilters.Tags = filters.tags;
        }

        if (filters['!tags']) {
            aFilters['Exclude Tags'] = filters['!tags'];
        }

        // Add avoidances if there are any
        if (filters['!ingredient_tags']) {
            aFilters.Avoidances = filters['!ingredient_tags'];
        }

        if (filters['merchant.uuid']) {
            aFilters['Merchant Name'] = filters['merchant.name'] || 'Unknown';
            aFilters['Merchant UUID'] = filters['merchant.uuid'];
        }

        analytics.track(eventName, {
            Terms: terms,
            'Sort By': sort,
            ...aFilters,
            ...properties,
        });
    },

    advancedSearch(properties = {}) {
        analytics.track('Advanced Search', properties);
    },

    addSwapShowMore(properties = {}) {
        analytics.track('Add/Swap Show More', properties);
    },

    searchSmartChoices(properties = {}) {
        analytics.track('Search Smart Choices', properties);
    },

    // searchFoodsAndRecipes(properties = {}) {
    //     analytics.track('Search Foods and Recipes', properties);
    // },

    searchRestaurantLocations(properties = {}) {
        analytics.track('Search Restaurant Locations', properties);
    },

    viewRestaurantMenu(properties = {}) {
        analytics.track('View Restaurant Menu', properties);
    },

    // searchRestaurantProducts(properties = {}) {
    //     analytics.track('View Restaurant Menu', properties);
    // },

    userLocated(properties = {}) {
        analytics.track('User Located', properties);
    },

    searchFavorites(properties = {}) {
        analytics.track('Search Favorites', properties);
    },

    startCreateCustomMeal(properties = {}) {
        analytics.track('Start Create Custom Meal', properties);
    },

    // savedCustomMeal(properties = {}) {
    //     analytics.track('Saved Custom Meal', properties);
    // },

    viewRecipe(properties = {}) {
        analytics.track('View Recipe', properties);
    },

    // useRecipe(recipe, date, meal) {
    //     const properties = {
    //         'Recipe ID': recipe.uuid,
    //         'Recipe Name': recipe.title,
    //         'Meal Date': date,
    //         'Meal Name': meal,
    //     };

    //     analytics.track('Add Recipe to Planner', properties);
    // },

    onboarding(stage, extras) {
        analytics.track('End User Onboarding', {
            Stage: stage,
            ...extras,
        });
    },

    completeOnboarding() {
        analytics.track('Complete Onboarding');
    },

    proOnboarding(stage, extras) {
        analytics.track('PRO Onboarding', {
            Stage: stage,
            ...extras,
        });
    },

    proOnboardingError(stage, error, extras) {
        analytics.track('PRO Onboarding Error', {
            Stage: stage,
            Error: error,
            ...extras,
        });
    },

    /**
     *  Meal Plan Editor Events
     */
    customizePlan(plan, properties = {}) {
        analytics.track('View Meal Plan Customizer', {
            'Meal Plan UUID': plan.uuid,
            'Meal Plan Name': plan.title,
            ...properties,
        });
    },

    scheduleMealPlan(properties = {}) {
        analytics.track('Schedule Meal Plan', properties);
    },

    viewMealPlanNutrition(properties = {}) {
        analytics.track('View Meal Plan Nutrition', properties);
    },

    startAddRecipe(properties = {}) {
        analytics.track('Start Add a Recipe', properties);
    },

    addRecipe(properties = {}) {
        analytics.track('Add a Recipe', properties);
    },

    startReplaceRecipe(properties = {}) {
        analytics.track('Start Replace a Meal Plan Recipe', properties);
    },

    // replaceRecipe(replacedRecipe, replacedSide, newRecipe, newSide, plan, properties = {}) {
    //     const properties = {
    //         'Replaced Recipe ID': replacedRecipe.uuid,
    //         'New Recipe ID': newRecipe.uuid,
    //         'Meal Plan ID': plan.uuid,
    //         ...properties,
    //     };

    //     if (replacedSide) {
    //         properties['Replaced Side ID'] = replacedSide.uuid;
    //         properties['Replaced Side Name'] = replacedSide.title;
    //     }

    //     if (newSide) {
    //         properties['New Side ID'] = newSide.uuid;
    //         properties['New Side Name'] = newSide.title;
    //     }

    //     analytics.track('Replace a Recipe', properties);
    // },

    removeRecipe(properties = {}) {
        analytics.track('Remove Recipe From Meal Plan Customizer', properties);
    },

    // schedulePlan(plan, properties = {}) {
    //     const properties = {
    //         'Meal Plan ID': plan.uuid,
    //         'Meal Plan Name': plan.title,
    //         ...properties
    //     };

    //     analytics.track('Schedule Plan', properties);
    // },

    startRecommendation() {
        analytics.track('Start Recommend Meal Plan');
    },

    sendRecommendation() {
        analytics.track('Recommend Meal Plan');
    },

    downloadMealPlanPDF(properties = {}) {
        analytics.track('Download Meal Plan PDF', properties);
    },
    downloadRecipePDF(properties = {}) {
        analytics.track('Download Or Print Recipe PDF', properties);
    },
    downloadGridPDF(properties = {}) {
        analytics.track('Download Grid PDF', properties);
    },
    downloadCollectionPDF(properties = {}) {
        analytics.track('Download Collection PDF', properties);
    },

    /**
     * Meal Feed Events
     */
    // viewMyPlanner(properties = {}) {
    //     analytics.track('View My Planner', properties);
    // },
    changeDefaultMealType(properties = {}) {
        analytics.track('Change Up Next Meal', properties);
    },
    expandView(properties = {}) {
        analytics.track('Toggle Meal Feed View Expand', properties);
    },
    collapseView(properties = {}) {
        analytics.track('Toggle Meal Feed View Collapse', properties);
    },
    startSwapMeal(properties = {}) {
        analytics.track('Start Swap Meal', properties);
    },
    startClearMeal(properties = {}) {
        analytics.track('Start Clear Meal', properties);
    },
    clearMeal(properties = {}) {
        analytics.track('Clear Meal', properties);
    },
    addMeal(properties = {}) {
        if (properties['Search Term']) {
            properties = { ...properties, 'Search Session ID': _sessionId.search };
        }
        analytics.track('Add Meal', properties);
    },
    swapMeal(properties = {}) {
        analytics.track('Swap Meal', properties);
    },
    dropMeal(properties = {}) {
        analytics.track('Drop Meal', properties);
    },
    viewMeal(properties = {}) {
        analytics.track('View Meal', properties);
    },
    viewNutritionDeviations(properties = {}) {
        analytics.track('View Nutrition Deviation Warning', properties);
    },
    editMeal(properties = {}) {
        analytics.track('Start Edit Servings', properties);
    },
    saveMeal(properties = {}) {
        analytics.track('Edit Servings', properties);
    },
    userDidEat(properties = {}) {
        analytics.track('User Did Eat', properties);
    },
    userDidNotEat(properties = {}) {
        analytics.track('User Did Not Eat', properties);
    },
    reportIssue(properties = {}) {
        analytics.track('Report Issue', properties);
    },
    addMealToGroceryList(properties = {}) {
        analytics.track('Add Meal to Groceries', properties);
    },
    alreadyBoughtThis(properties = {}) {
        analytics.track('Already Bought This', properties);
    },
    addCustomGrocery(properties = {}) {
        analytics.track('Add Custom Grocery', properties);
    },
    ingredientsOnHand(properties = {}) {
        analytics.track('Ingredients On Hand', properties);
    },
    removeMealFromGroceries(properties) {
        analytics.track('Remove Meal from Groceries', properties);
    },
    onGroceryListChange() {
        analytics.track('Grocery List Updated');
    },

    /**
     * Daily Log Events
     */
    viewDailyLog() {
        analytics.track('View Daily Log');
    },

    /**
     * Subscription Events
     */
    createSubscriptionFailed(plan, coupon, error) {
        const properties = {
            'Subscription Plan': plan,
            'Coupon Code': coupon,
            Error: error,
        };

        analytics.track('Create Subscription Failed', properties);
    },

    createSubscription(user, plan, coupon) {
        const properties = {
            'Subscription Plan': plan,
            'Coupon Code': coupon,
        };

        analytics.track('Create Subscription', properties);
    },

    paymentEntryError(properties = {}) {
        analytics.track('Payment Entry Error', properties);
    },

    // changeSubscriptionFailed(plan, error) {
    //     const properties = {
    //         'Subscription Plan': plan,
    //         'Error': error,
    //     };

    //     analytics.track('Change Subscription Failed', properties);
    // },

    // changeSubscription(user, plan) {
    //     const properties = {
    //         'Subscription Plan': plan,
    //     };

    //     analytics.track('Change Subscription', properties);
    // },

    // updatePaymentCardFailed(coupon, error) {
    //     const properties = {
    //         'Coupon Code': coupon,
    //         'Error': error,
    //     };

    //     analytics.track('Update Payment Card Failed', properties);
    // },

    // updatePaymentCard(user, coupon) {
    //     const properties = {
    //         'Coupon Code': coupon,
    //     };

    //     analytics.track('Update Payment Card', properties);
    // },

    // redeemGiftCode(user, giftCode) {
    //     analytics.track('Redeem Gift Code', {
    //         'New Subscription Plan': user.subscription_plan,
    //         'New Active Until': user.active_until,
    //         'Gift Code': giftCode,
    //     });
    // },

    // redeemGiftCodeFailure(error, giftCode) {
    //     analytics.track('Redeem Gift Code Failure', {
    //         'Error Message': error,
    //         'Gift Code': giftCode,
    //     });
    // },

    /**
     * Preferences / Account Settings Events
     */
    // savePassword() {
    //     analytics.track('Save Password');
    // },

    // savePasswordError(error) {
    //     analytics.track('Save Password Error', {error});
    // },

    changeNutritionRx(properties = {}) {
        analytics.track('End User Updates Nutrition Prescription', properties);
    },

    changeNutritionRxError(properties = {}) {
        analytics.track('End User Change Nutrition Error', properties);
    },
    editPreferences(properties = {}) {
        analytics.track('View Preferences', properties);
    },
    savePreferences(properties = {}) {
        analytics.track('Save Preferences', properties);
    },
    viewNutritionDetails(properties = {}) {
        analytics.track('View Nutrition Prescription', properties);
    },

    /**
     * Grocery List Events
     * @return {[type]} [description]
     */
    viewGroceryList() {
        analytics.track('View Grocery List');
    },

    clearGroceryList(properties = {}) {
        analytics.track('Clear Entire Grocery List', properties);
    },

    toggleGroceryRow(properties = {}) {
        analytics.track('Toggle Grocery Row', properties);
    },

    printGroceryList() {
        analytics.track('Print Grocery List');
    },

    emailGroceryList() {
        analytics.track('Email Grocery List');
    },

    submitGroceryListForReview() {
        analytics.track('Submit Grocery Review');
    },

    startBuyOnAmazonFresh(properties = {}) {
        analytics.track('Start Buy On AmazonFresh', properties);
    },

    buyOnAmazonFresh(properties = {}) {
        analytics.track('Buy On AmazonFresh', properties);
    },

    closeBuyOnAmazonFresh(properties = {}) {
        analytics.track('Close Buy on AmazonFresh', properties);
    },

    startBuyOnInstacart(properties = {}) {
        analytics.track('Start Buy On Instacart', properties);
    },

    buyOnInstacart(properties = {}) {
        analytics.track('Buy On Instacart', properties);
    },

    closeBuyOnInstacart(properties = {}) {
        analytics.track('Close Buy on Instacart', properties);
    },

    startBuyOnWalmart(properties = {}) {
        analytics.track('Start Buy On Walmart', properties);
    },

    buyOnWalmart(properties = {}) {
        analytics.track('Buy On Walmart', properties);
    },

    closeBuyOnWalmart(properties = {}) {
        analytics.track('Close Buy on Walmart', properties);
    },

    /** Sunbasket Events **/

    startBuyOnSunbasket(properties = {}) {
        analytics.track('Start Buy On Sunbasket', properties);
    },

    submitOrderSunbasket(properties = {}) {
        analytics.track('Submit Order to Sunbasket', properties);
    },

    buyOnSunbasket(properties = {}) {
        analytics.track('Buy On Sunbasket', properties);
    },

    buyOnSunbasketOrderValidationError(properties = {}) {
        analytics.track('Buy on Sunbasket Order Validation Error', properties);
    },

    addSunbasketWeekGroceries(properties = {}) {
        analytics.track('Add Sunbasket Week to Groceries', properties);
    },

    removeSunbasketWeekGroceries(properties = {}) {
        analytics.track('Remove Sunbasket Week from Groceries', properties);
    },

    addMoreSunbasketMeals(properties = {}) {
        analytics.track('Add More Sunbasket Meals', properties);
    },

    closeBuyOnSunbasket(properties = {}) {
        analytics.track('Close Buy on Sunbasket', properties);
    },

    openErrorDialogSunbasket(properties = {}) {
        analytics.track('Sunbasket Outside Delivery Window Alert', properties);
    },

    goBackErrorDialogSunbasket(properties = {}) {
        analytics.track('Sunbasket Alert: Abort Meal Add', properties);
    },

    alreadyPurchasedErrorDialogSunbasket(properties = {}) {
        analytics.track('Sunbasket Alert: Indicate Already Purchased', properties);
    },

    openChooseShop(properties = {}) {
        analytics.track('Open Choose Shop', properties);
    },

    closeChooseShop(properties = {}) {
        analytics.track('Close Choose Shop', properties);
    },

    chooseShop(properties = {}) {
        analytics.track('Choose Shop', properties);
    },

    preferShopOwn(properties = {}) {
        analytics.track('Prefer to shop on own', properties);
    },

    /** Favorites Events **/
    saveFavorite(board, content) {
        const properties = {
            'Board Name': board.name,
            'Board UUID': board.uuid,
        };

        if (content.type === 'recipe') {
            properties['Recipe Name'] = content.title;
            properties['Recipe UUID'] = content.uuid;
        } else if (content.type === 'plan') {
            properties['Meal Plan Name'] = content.title;
            properties['Meal Plan UUID'] = content.uuid;
        } else if (content.type === 'combo') {
            properties['Combo Name'] = content.title;
            properties['Combo UUID'] = content.uuid;
        }

        analytics.track('Save Favorite', properties);
    },

    removeFavorite(board, content) {
        const properties = {
            'Board Name': board.name,
            'Board UUID': board.uuid,
            'Content Type': content.type,
        };

        if (content.type === 'recipe') {
            properties['Recipe Name'] = content.title;
            properties['Recipe UUID'] = content.uuid;
        } else {
            properties['Meal Plan Name'] = content.title;
            properties['Meal Plan UUID'] = content.uuid;
        }

        analytics.track('Remove Favorite', properties);
    },

    // editBoard(board) {
    //     const properties = {
    //         'Board Name': board.name,
    //         'Board ID': board.uuid,
    //     };

    //     analytics.track('Edit Board', properties);
    // },

    // deleteBoard(board) {
    //     const properties = {
    //         'Board Name': board.name,
    //         'Board ID': board.uuid,
    //     };

    //     analytics.track('Delete Board', properties);
    // },

    /**  Try it Out Form Events **/
    loadTryItOut() {
        analytics.track('Load MP Wizard');
    },

    tryItOutError(error) {
        analytics.track('MP Wizard Error', { Error: error });
    },

    openTryItOutAvoidances() {
        analytics.track('Open MP Wizard Avoidances');
    },

    openTryItOutEnergy() {
        analytics.track('Open MP Wizard Energy Estimator');
    },

    tryItOutEnergyError(error) {
        analytics.track('MP Wizard Energy Estimator Error', { Error: error });
    },

    saveTryItOutEnergy() {
        analytics.track('Save MP Wizard Energy Estimator');
    },

    /** EatLove PRO misc events **/
    startCreatePatient() {
        analytics.track('Start Create Patient / Client');
    },

    createPatient(uuid, stage) {
        const properties = {
            'Patient UUID': uuid,
            Stage: stage,
        };
        analytics.track('Create Patient / Client', properties);
    },

    viewAgreement(version) {
        analytics.track('View Business Associates Agreement', { Version: version });
    },

    acceptAgreement(version) {
        analytics.track('Accept Business Associates Agreement', { Version: version });
    },

    viewToolkit() {
        analytics.track('View Toolkit');
    },

    viewToolkitAsset(asset, properties = {}) {
        analytics.track('View Toolkit Asset', { 'Asset Title': asset.title, ...properties });
    },

    viewLearn() {
        analytics.track('View Learn Page');
    },

    viewLearnVideo(title, asset) {
        analytics.track('View Learn Video', { 'Asset Title': title, Asset: asset });
    },

    downloadLearnAsset(asset) {
        analytics.track('Download Learn Asset', { Asset: asset });
    },

    viewInviteFriends() {
        analytics.track('View Invite Friends');
    },

    copyReferralUrlToClipboard() {
        analytics.track('Copy Referral URL to Clipboard');
    },

    shareReferralUrlOnFacebook() {
        analytics.track('Share Referral URL on Facebook');
    },

    // shareReferralUrlOnTwitter() {
    //     analytics.track('Share Referral URL on Twitter');
    // },

    acceptPatientInvite() {
        analytics.track('Accept Patient / Client Invite');
    },

    sendPatientInvite(properties = {}) {
        analytics.track('Send Patient / Client Invite', properties);
    },

    acceptProInvite() {
        analytics.track('Accept Pro Invite');
    },

    sendProInvite(properties = {}) {
        analytics.track('Send Pro Invite', properties);
    },

    updatePatientBiometrics(properties = {}) {
        analytics.track('Update Patient / Client Biometrics', properties);
    },

    updatePatientCondition(properties = {}) {
        analytics.track('Update Patient / Client Prescription Template', properties);
    },

    updatePatientEnergy(properties = {}) {
        analytics.track('Update Patient / Client Energy', properties);
    },

    updatePatientNutrition(properties = {}) {
        analytics.track('Update Patient / Client Nutrition', properties);
    },

    updatePatientTastePreferences(properties = {}) {
        analytics.track('Update Patient / Client Taste Preferences', properties);
    },

    updatePatientFamily(properties = {}) {
        analytics.track('Update Patient / Client Family', properties);
    },

    updatePatientCarePlan() {
        analytics.track('Update Patient / Client Care Plan');
    },

    updatePatientMealPreferences(properties = {}) {
        analytics.track('Update Patient / Client Meal Preferences', properties);
    },

    viewConsumerHomepage(properties = {}) {
        analytics.track('View Consumer Homepage', properties, true);
    },

    viewGeneralProLanding(properties = {}) {
        analytics.track('View General PRO Landing', properties, true);
    },

    viewMedicalLanding(properties = {}) {
        analytics.track('View Medical Landing', properties, true);
    },

    viewFitnessLanding(properties = {}) {
        analytics.track('View Fitness Landing', properties, true);
    },

    viewHealthPlanLanding(properties = {}) {
        analytics.track('View Health Plan Landing', properties, true);
    },

    viewMedicalGroupsLanding(properties = {}) {
        analytics.track('View Medical Groups Landing', properties, true);
    },

    viewRetailersLanding(properties = {}) {
        analytics.track('View Retailers Landing', properties, true);
    },

    viewRestaurantsLanding(properties = {}) {
        analytics.track('View Restaurants Landing', properties, true);
    },

    viewDeveloperApiLanding(properties = {}) {
        analytics.track('View Developer API Landing', properties, true);
    },

    startUseOwnRecipe(properties = {}) {
        analytics.track('Start Use Own Recipe', properties);
    },

    addIngredientToRecipe(properties = {}) {
        analytics.track('Add Ingredient to Recipe', properties);
    },

    swapIngredientFoodInRecipe(properties = {}) {
        analytics.track('Swap Ingredient in Recipe', properties);
    },
    editIngredientAmountInRecipe(properties = {}) {
        analytics.track('Edit Ingredient Amount in Recipe', properties);
    },

    savedOwnRecipe(properties = {}) {
        analytics.track('Saved Recipe', properties);
    },

    searchIngredients(terms, filters, sort, properties = {}) {
        this._eventWithTerms('Search Ingredients for Recipe', terms, filters, sort, properties);
    },

    startUseOwnFood(properties = {}) {
        analytics.track('Start Use Own Food', properties);
    },

    savedCustomFood(properties = {}) {
        analytics.track('Saved Custom Food', properties);
    },

    // debug(properties = {}) {
    //     analytics.track('Debug', properties = {});
    // },

    requestMemberSuccess(properties = {}) {
        analytics.track('Request Member Success', properties);
    },

    basicMealsImpression() {
        analytics.track('Basic Banner Impression');
    },

    basicMealsAccept() {
        analytics.track('Basic Banner Accept');
    },

    startBarcodeCapture(properties = {}) {
        analytics.track('Start Barcode Capture', properties);
    },

    startBarcodeScan(properties = {}) {
        analytics.track('Start Barcode Scan', properties);
    },

    barcodeScanError(properties = {}) {
        analytics.track('Barcode Scan Error', properties);
    },

    scannedBarcodeFound(properties = {}) {
        analytics.track('Scanned Barcode', properties);
    },

    scannedBarcodeNotFound(properties = {}) {
        analytics.track('Barcode Not Found', properties);
    },

    submitSurvey(properties = {}) {
        analytics.track('Submit Survey', properties);
    },

    startCreateCollection(properties = {}) {
        analytics.track('Start Create Collection', properties);
    },

    openCollection(collection, properties = {}) {
        analytics.track('Open Collection', {
            'Collection UUID': collection.uuid,
            Title: collection.title,
            ...properties,
        });
    },

    saveNewCollection(collection, properties = {}) {
        analytics.track('Save New Collection', {
            'Collection UUID': collection.uuid,
            Title: collection.title,
            ...properties,
        });
    },

    // logCurrentWeight(properties = {}) {
    //     analytics.track('Log Current Weight', {...properties, })
    // },

    saveWater(properties = {}) {
        analytics.track('Save Water Intake', { ...properties });
    },

    startExportNutrition(properties = {}) {
        analytics.track('Start Nutrition Report', properties);
    },

    downloadNutritionReport(properties = {}) {
        analytics.track('Download Nutrition Report', properties);
    },

    downloadNutritionError(properties = {}) {
        analytics.track('Download Nutrition Error', properties);
    },

    emailNutritionReport(properties = {}) {
        analytics.track('Email Nutrition Report', { ...properties });
    },

    emailNutritionError(properties = {}) {
        analytics.track('Error Email Nutrition Report', { ...properties });
    },

    emailNutritionError(properties = {}) {
        analytics.track('Email Nutrition Error', properties);
    },

    incompleteProfile(properties = {}) {
        analytics.track('Completed Steps', properties);
    },

    failedFoodScan(properties = {}) {
        analytics.track('Failed Food Scan', properties);
    },

    unableToPurchase(properties = {}) {
        analytics.track('Purchase Required To Access Feature', properties);
    },

    showUpgrade(properties = {}) {
        analytics.track('Show Upgrade', properties);
    },

    viewMembership(properties = {}) {
        analytics.track('View Membership', properties);
    },

    noMembershipsAvailable() {
        analytics.track('No Memberships Available');
    },

    clickDemoRequest(moduleName, properties = {}) {
        analytics.track(
            'Click Demo Request',
            {
                Button: moduleName,
                ...properties,
            },
            true
        );
    },

    clickSignUpButton(moduleName, properties = {}) {
        analytics.track(
            'Click Sign Up Button',
            {
                Button: moduleName,
                ...properties,
            },
            true
        );
    },

    refreshMeal() {
        analytics.track('Refresh Meal');
    },

    refreshMealFallback() {
        analytics.track('Refresh Meal Fallback');
    },

    promptToRegenerateMealFeed(properties = {}) {
        analytics.track('Prompt to Regenerate Meal Feed', properties);
    },

    keepMeals(properties = {}) {
        analytics.track('Keep Meals As Planned', properties);
    },

    changeMeals(properties = {}) {
        analytics.track('Change Meals', properties);
    },

    changeMealsCompleted(properties = {}) {
        analytics.track('Change Meals Completed', properties);
    },

    changeMealsError(properties = {}) {
        analytics.track('Change Meals Error', properties);
    },

    editUser(properties = {}) {
        analytics.track('Edit User', properties);
    },

    clearThisDay(properties = {}) {
        analytics.track('Clear this day', properties);
    },

    clearNext7Days(properties = {}) {
        analytics.track('Clear the next 7 days', properties);
    },

    planGenerationError(properties = {}) {
        analytics.track('Meal Plan Generation Failure', properties);
    },

    dragAndDropMeal(properties = {}) {
        analytics.track('Drag and Drop Meal', properties);
    },

    enterWeight(properties = {}) {
        analytics.track('Enter Weight', properties);
    },

    openAddAllMealsModal() {
        analytics.track('Open Add All Meals Modal');
    },

    closeAddAllMealsModal() {
        analytics.track('Close Add All Meals Modal');
    },

    addMealsWithinDateRange(properties = {}) {
        analytics.track('Add Meals within Date Range', properties);
    },
    autoPopulate(startDate, properties = {}) {
        analytics.track('Auto-populate meals on start date', { 'Start Date': startDate, ...properties });
    },

    oAuthAuthorize(context, properties = {}) {
        analytics.track('OAuth Authorize - ' + context, properties);
    },

    fileUploadFailed(fileSize) {
        analytics.track('File Upload Failed', { 'File Size': fileSize });
    },

    /**
     * Importing Recipes from URLs and other documents
     */
    startImportRecipe(properties = {}) {
        analytics.track('Start Import Recipe', properties);
    },
    importRecipeSuccess(properties = {}) {
        analytics.track('Import Recipe', properties);
    },
    importRecipeError(properties = {}) {
        analytics.track('Import Recipe Error', properties);
    },

    /**
     * Importing Recipes from URLs and other documents
     */
    startQuickAddIngredients(properties = {}) {
        analytics.track('Start Quick Add Ingredients', properties);
    },
    cancelQuickAddIngredients(properties = {}) {
        analytics.track('Cancel Quick Add Ingredients', properties);
    },
    quickAddIngredientsSuccess(properties = {}) {
        analytics.track('Quick Add Ingredients Success', properties);
    },
    quickAddIngredientsError(properties = {}) {
        analytics.track('Quick Add Ingredients Error', properties);
    },



    openQuickLogAI(properties = {}) { // fired when the quick log AI dialog is opened
        analytics.track('Open Quick Log AI', properties);
    },
    startQuickLogAI(properties = {}) { // Fired when the user submits quick log AI
        analytics.track('Start Quick Log AI', properties);
    },
    closeQuickLogAI(properties = {}) { // fired if they close the modal without submitting
        analytics.track('Close Quick Log AI', properties);
    },
    cancelQuickLogAI(properties = {}) { // fired if they close the modal while its processing
        analytics.track('Cancel Quick Log AI', properties);
    },
    quickLogAIError(properties = {}) { // fired if validation fails, the submit POST or the job itself fails
        analytics.track('Quick Log AI Error', properties);
    },
    quickLogAISuccess(properties = {}) { // fired when the job completes successfully
        analytics.track('Quick Log AI Success', properties);
    },


    quickLogAIChangeFoodAmount(properties = {}) { // any change to any ingredient fires this event
        analytics.track('Quick Log AI Change Food Amount', properties);
    },
    quickLogAISwapFood(properties = {}) { // any change to any ingredient fires this event
        analytics.track('Quick Log AI Swap Food', properties);
    },
    quickLogAIDeleteFood(properties = {}) { // any change to any ingredient fires this event
        analytics.track('Quick Log AI Delete Food', properties);
    },
    closeQuickLogAIConfirmation(properties = {}) { // If the user cancels or closes the confirmation window
        analytics.track('Close Quick Log AI Confirmation', properties);
    },
    quickLogAIConfirmed(properties = {}) { // fired when the user saves their quick log confirmation.
        analytics.track('Quick Log AI Confirmed', properties);
    },




    viewBlog(properties = {}) {
        analytics.track('View Blog', properties);
    },

    enteredNonNumericBarcode(properties = {}) {
        analytics.track('Entered Non-Numeric Barcode', properties);
    },

    scannedNonNumericBarcode(properties = {}) {
        analytics.track('Scanned Non-Numeric Barcode', properties);
    },

    enteredInvalidLengthBarcode(properties = {}) {
        analytics.track('Entered Invalid Length Barcode', properties);
    },

    scannedInvalidLengthBarcode(properties = {}) {
        analytics.track('Scanned Invalid Length Barcode', properties);
    },

    changeMealPortionSize({ amount, unit, prevAmount, prevUnit, patient }) {
        const properties = {
            'Previous logged_amount': prevAmount,
            'Previous logged_unit': prevUnit,
            'Current logged_amount': amount,
            'Current logged_unit': unit,
            'Patient Uuid': patient,
        };

        if (!patient) {
            delete properties['Patient Uuid'];
        }

        analytics.track('Change Meal Portion Size', properties);
    },

    onboardingProfileMealTypesUpdate(properties = {}) {
        analytics.track('Onboarding Update Meal Types', properties);
    },

    confirmOverwriteModalClose(properties = {}) {
        analytics.track('Confirm Overwrite Modal Close', properties);
    },

    confirmOverwriteModalCancel(properties = {}) {
        analytics.track('Confirm Overwrite Modal Cancel', properties);
    },

    confirmOverwriteModalChangeDropdown(properties = {}) {
        analytics.track('Confirm Overwrite Change Dropdown', properties);
    },

    confirmOverwriteModalConfirm(properties = {}) {
        analytics.track('Confirm Overwrite Confirm', properties);
    },

};
