'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import ImgResized from '@components/Widgets/ImgResized.react';
import SmartFraction from '@components/Widgets/SmartFraction.react';

import { flattenIngredients } from '@utils/Recipe';
import { roundForHumans, inflectUnitOfMeasure } from '@utils/Math';

import './FoodDetailsCard.scss';

export default class FoodDetailsCard extends Component {
    static propTypes = {
        foodDetails: PropTypes.object,
    };

    static contextTypes = {
        addSwapContext: PropTypes.object,
        user: PropTypes.object,

        onSelectFoodDetails: PropTypes.func,
        showFoodDetailsInfo: PropTypes.func,
    };

    onClick = () => {
        const { onSelectFoodDetails, showFoodDetailsInfo } = this.context;
        const { foodDetails } = this.props;

        if (onSelectFoodDetails) {
            onSelectFoodDetails(foodDetails);
        } else if (showFoodDetailsInfo) {
            showFoodDetailsInfo(foodDetails);
        }
    };

    render = () => {
        const { foodDetails } = this.props;
        const { user = {} } = this.context;
        const { hide_nutrition = false } = user.preferences || {};

        const ingredients = flattenIngredients({foodDetails});

        return (
            <li className="results-mini-card food-details-mini-card" onClick={this.onClick}>
                {foodDetails.image ? (
                    <ImgResized className="thumb" src={foodDetails.image} width={100} height={100} />
                ) : (
                    <i className="feather feather-file-text thumb" />
                )}
                <span
                    className="title"
                    title={[foodDetails.brand_name, foodDetails.title].filter((v) => v).join(' - ')}
                >
                    {foodDetails.title}
                </span>

                <span className="l2">
                    {foodDetails.brand_name ? <span className="brand">{foodDetails.brand_name}</span> : null}
                </span>
            </li>
        );
    };
}
