'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import moment from 'moment';

import RecipeCard from './SmartChoices/RecipeCard.react';
import ComboCard from './SmartChoices/ComboCard.react';
import ProductCard from './SmartChoices/ProductCard.react';
import Paginator from '../../Paginator.react';
import SearchKeywords from '../../Filters/SearchKeywords.react';
import Alert from '../../../Widgets/Alert/Alert.react';
import PostalCodeSelector from '../../../Groceries/PostalCodeSelector.react';

import UserActions from '../../../../actions/UserActions';
import AuthStore from '../../../../stores/AuthStore';
import UserStore from '../../../../stores/UserStore';
import { updateCachedDocuments } from '../../../../utils/Content';
import { getMealSearchParamsForProfile, setRecommendedModeSourceParameters } from '../../../../pro/utils/Patients';
import Analytics from '../../../../utils/Analytics';
import { getConfig } from '../../../../utils/Env';
import { hasDeadlineExpired } from '../../../../utils/Sunbasket';
import { getMaxCostPerServingForMealType } from '../../../../utils/Meals';

import './SmartChoices.scss';
import './Restaurants.scss';

export default class SmartChoices extends Component {
    static propTypes = {
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
        searchTermsPlaceholder: PropTypes.string,
    };

    static defaultProps = {
        searchTermsPlaceholder: 'SEARCH SMART CHOICES',
        searchTermsProp: 'terms',
    };

    static contextTypes = {
        profile: PropTypes.object,
        addSwapContext: PropTypes.object,
        recipes: PropTypes.object,
        details: PropTypes.object,
        foods: PropTypes.object,

        showFullBrowser: PropTypes.func,

        isPro: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const { postal_code, preferences } = UserStore.getUser();
        const { meal_kit_providers = null } = preferences;

        this.state = {
            lastParams: JSON.stringify(props.params),
            params: this.initializeFilters(props.params, props.mealType, preferences),
            results: [],
            fallbacks: [],
            total: 0,
            loading: false,
            loadingMore: false,
            dirty: false,
            mode: 'recipes',
            meal_kit_providers,
            postal_code,
            isModalOpen: false,
            preferences: preferences,
            capturePostalCodeWorking: false,
            capturePostalCodeAlert: null
        };

        this.debounceSearch = debounce(this.search, 750);
    }

    _mounted = false;

    componentDidMount = () => {
        this._mounted = true;

        if (!this.props.inhibitSearchOnMount) {
            this.search();
        }
    }

    componentWillUnmount = () => {
        this._mounted = false;
    }

    addExclusionFilters = (results, filters = {}) => {
        filters['!uuid'] = filters['!uuid'] || [];
        filters['!main_dish'] = filters['!main_dish'] || [];
        filters['!side_dish'] = filters['!side_dish'] || [];
        results.forEach(r => {
            if (r.type === 'recipe') {
                if (!filters['!uuid'].includes(r.uuid)) {
                    filters['!uuid'].push(r.uuid);
                }

                if (!filters['!main_dish'].includes(r.uuid)) {
                    filters['!main_dish'].push(r.uuid);
                }
            }

            if (r.type === 'combo') {
                if (!filters['!uuid'].includes(r.main_dish)) {
                    filters['!uuid'].push(r.main_dish);
                }

                if (!filters['!main_dish'].includes(r.main_dish)) {
                    filters['!main_dish'].push(r.main_dish);
                }

                if (!filters['!uuid'].includes(r.side_dish)) {
                    filters['!uuid'].push(r.side_dish);
                }

                if (!filters['!side_dish'].includes(r.side_dish)) {
                    filters['!side_dish'].push(r.side_dish);
                }
            }
        });

        return filters
    }

    appendSunbasketParams = async (params) => {
        const { addSwapContext } = this.context;
        const { postal_code } = this.state;

        const searchDate = moment(addSwapContext.date);

        const deliveryInfo = await AuthStore.fetch(
            getConfig('recipe_api') + '/sunbasket/zipcode-delivery-info?' + [
                'date=' + encodeURIComponent(addSwapContext.date.format('YYYY-MM-DD')),
                'postalCode=' + encodeURIComponent(postal_code),
            ].join('&')
        );

        if (!deliveryInfo.meal_provider_dates?.includes(searchDate.format('YYYY-MM-DD'))) {
            return params;
        }

        params.filters.store.push('sunbasket');
        params.filters.available_on = [
            'always',
            searchDate.format('YYYY-MM-DD'),
            searchDate.subtract(1, 'day').format('YYYY-MM-DD'),
            searchDate.subtract(1, 'day').format('YYYY-MM-DD'),
            searchDate.subtract(1, 'day').format('YYYY-MM-DD'),
            searchDate.subtract(1, 'day').format('YYYY-MM-DD'),
        ];

        params.filters.available_on = params.filters.available_on.filter((date)=> !hasDeadlineExpired(date));

        // Don't recommend us stuff that's not available
        params.filters['!food_uuid'] = (params.filters['!food_uuid'] || []).concat(deliveryInfo?.unavailable || []);

        return params;
    }

    initializeFilters = (params, mealType, preferences) => {
        params.filters['!tags'] = params.filters['!tags'] || [];
        params.filters['!tags'].push('Ready Made Meal');
        params.filters['avg_cost_per_serving'] = getMaxCostPerServingForMealType(mealType, preferences);
        return params;
    }

    prepareFilters = async (mode, params) => {
        const { isPro } = this.context;

        if (mode == 'recipes') {
            params.filters['!tags'] = params.filters['!tags'] || [];
            params.filters['!tags'].push('Ready Made Meal');
        }


        if (['meal-kits', 'ready-made-meals'].includes(mode)) {
            const { meal_kit_providers = [] } = this.state;
            params.types = ['product'];
            params.filters.store = ['universal'];
            params.filters.product_type = mode ==  'meal-kits' ? 'Meal Kit' : 'Ready Made Meal';

            if (!isPro) {
                 for (const i in meal_kit_providers) {
                    if (meal_kit_providers[i] === 'sunbasket') {
                        params = await this.appendSunbasketParams(params);
                    }

                    // @todo add more providers here later
                }
            }
        }

        return params;
    }

    setMode = async (mode) => {
        this.setState({loading: true});
        const { postal_code } = this.state;

        const isModalOpen = ['meal-kits', 'ready-made-meals'].includes(mode) && !postal_code?.length;

        if (isModalOpen) {
            this.setState({mode, isModalOpen});
        } else {
            const params = await this.prepareFilters(mode, JSON.parse(JSON.stringify(this.props.params)));
            this.setState({mode, params, isModalOpen, capturePostalCodeWorking: false, capturePostalCodeAlert: null}, this.debounceSearch);
        }
    }

    search = async () => {
        const { addSwapContext } = this.context;
        const { mode, params } = this.state;
        params.size = 3;

        this.setState({loading: true, dirty: true, results: [], fallbacks: [], suggestions: null, total: 0, fallbackTotal: 0});

        const url = mode == 'recipes' ? '/search-uniques' : '/search-products';

        try {
            const results = await AuthStore.fetch(getConfig('recipe_api') + url, {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
                body: JSON.stringify(params),
            }, true);

            // Courtesy update of in-memory caching
            if (results && results.elements) {
                updateCachedDocuments(results.elements);
            }

            if (results.elements.length == 0) {
                return this.searchFallback();
            }

            Analytics.searchSmartChoices({
                "Mode": mode,
                "Date": moment(addSwapContext.date) ?? null,
                "Meal Type": addSwapContext.mealType,
                "Total Results": results.total > 6 ? results.total : results.elements.length,
            });

            this.setState({
                results: results.elements,
                fallbacks: [],
                suggestion: results.suggestion,
                total: results.total > 6 ? results.total : results.elements.length,
                fallbackTotal: 0,
                resultsParams: params,
                loading: false,
            });

        } catch(error) {
            this.setState({loading: false, total: 0});
        }
    }

    showMore = () => {
        const { results, params, loadingMore, mode } = this.state;

        if (loadingMore) {
            return;
        }

        if (results.length >= 500) {
            return;
        }

        this.addExclusionFilters(results, params.filters);

        this.setState({loadingMore: true});


        Analytics.addSwapShowMore({'Search Tool': 'Smart Choices'});

        const url = mode == 'recipes' ? '/search-uniques' : '/search-products';

        AuthStore.fetch(getConfig('recipe_api') + url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify({
                ...params,
                size: 6,
            }),
        }, true).then(data => {

            // Courtesy update of in-memory caching
            if (data && data.elements) {
                updateCachedDocuments(data.elements);
            }

            const newResults = results.concat(data.elements);

            this.setState({
                results: newResults,
                total: data.total > 6 ? this.state.total : newResults.length,
                loading: false,
                loadingMore: false,
            });
        }, (error) => this.setState({ loading: false, loadingMore: false, total: 0 }));
    }

    searchFallback = () => {
        const { addSwapContext } = this.context;
        const { mode } = this.state;
        const { profile, mealType } = addSwapContext;

        // make up our own params, inherit from the main params
        const params = {
            ...this.state.params,
            ...getMealSearchParamsForProfile(mealType, profile)
        };

        params.types = this.state.params.types;
        params.filters.tags = this.state.params.filters.tags;
        params.filters['!tags'] = this.state.params.filters['!tags'];
        params.filters['!uuid'] = this.state.params.filters['!uuid'];
        params.filters['store'] = this.state.params.filters['store'];
        params.filters['product_type'] = this.state.params.filters['product_type'];
        params.filters['avg_cost_per_serving'] = getMaxCostPerServingForMealType(mealType, this.state.preferences);
        params.size = 3;

        setRecommendedModeSourceParameters('smart_choices', params, profile);

        const url = mode == 'recipes' ? '/search-uniques' : '/search-products';

        if (['meal-kits', 'ready-made-meals'].includes(mode)) {
            const searchDate = moment(addSwapContext.date);

            params.filters.available_on = [
                'always',
                searchDate.format('YYYY-MM-DD'),
                searchDate.subtract(1, 'day').format('YYYY-MM-DD'),
                searchDate.subtract(1, 'day').format('YYYY-MM-DD'),
                searchDate.subtract(1, 'day').format('YYYY-MM-DD'),
                searchDate.subtract(1, 'day').format('YYYY-MM-DD'),
            ];
        }

        AuthStore.fetch(getConfig('recipe_api') + url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify(params),
        }, true).then(results => {
            // Courtesy update of in-memory caching
            if (results && results.elements) {
                updateCachedDocuments(results.elements);
            }

            Analytics.searchSmartChoices({
                "Mode": mode,
                "Date": moment(addSwapContext.date) ?? null,
                "Meal Type": mealType,
                "Total Results": results.total > 6 ? results.total : results.elements.length,
                fallback: true
            });

            this.setState({
                results: [],
                total: 0,
                fallbacks: results.elements,
                fallbackTotal: results.total > 6 ? results.total : results.elements.length,
                fallbackParams: params,
                loading: false,
            });

        }, (error) => this.setState({loading: false, fallbackTotal: 0}));
    }

    showMoreFallbacks = () => {
        const { addSwapContext } = this.context;
        const { fallbacks, fallbackParams, loadingMore, mode } = this.state;

        if (loadingMore) {
            return;
        }

        if (fallbacks.length >= 500) {
            return;
        }

        this.addExclusionFilters(fallbacks, fallbackParams.filters);

        this.setState({loadingMore: true});

        Analytics.addSwapShowMore({'Search Tool': 'Smart Choices'});

        const url = mode == 'recipes' ? '/search-uniques' : '/search-products';

        if (['meal-kits', 'ready-made-meals'].includes(mode)) {
            const searchDate = moment(addSwapContext.date);

            fallbackParams.filters.available_on = [
                'always',
                searchDate.format('YYYY-MM-DD'),
                searchDate.subtract(1, 'day').format('YYYY-MM-DD'),
                searchDate.subtract(1, 'day').format('YYYY-MM-DD'),
                searchDate.subtract(1, 'day').format('YYYY-MM-DD'),
                searchDate.subtract(1, 'day').format('YYYY-MM-DD'),
            ];
        }

        AuthStore.fetch(getConfig('recipe_api') + url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify({
                ...fallbackParams,
                size: 6,
            }),
        }, true).then(data => {

            // Courtesy update of in-memory caching
            if (data && data.elements) {
                updateCachedDocuments(data.elements);
            }

            const newFallbacks = fallbacks.concat(data.elements);

            this.setState({
                fallbacks: newFallbacks,
                fallbackTotal: data.total > 6 ? this.state.fallbackTotal : newFallbacks.length,
                loading: false,
                loadingMore: false,
            });
        }, (error) => this.setState({ loading: false, loadingMore: false, fallbackTotal: 0 }));

    }

    onChangeParams = (params) => {
        this.setState({params}, this.debounceSearch);
    }

    switchToFullbrowser = () => {
        const { params } = this.state;
        const { showFullBrowser } = this.context;

        showFullBrowser({
            defaultTerms: params.terms,
            defaultTypes: ['recipe'],
            inhibitSearchOnMount: false,
        });
    }

    renderResult = (result, key) => {
        const { params } = this.state;
        if (result.type === 'recipe') {
            return <RecipeCard key={key} searchRanking={key + 1} recipe={result} params={params}/>
        }

        if (result.type === 'combo') {
            return <ComboCard key={key} searchRanking={key + 1} combo={result} params={params}/>
        }

        if (result.type == 'product') {
            return <ProductCard key={key} searchRanking={key + 1} product={result} params={params}/>
        }
    }

    renderModeSelectorButtons = () => {
        const { mode, meal_kit_providers } = this.state;
        const { addSwapContext, isPro } = this.context;
        const { mealType, profile } = addSwapContext
        const { inhibit_add_swap_modes } = profile?.preferences || {};
        const modePicker = [];

        if(!(inhibit_add_swap_modes?.includes('smart-choices::meal-kits') && inhibit_add_swap_modes?.includes('smart-choices::ready-made'))) {
            modePicker.push(
                <li key="recipes">
                    <button key="recipes" data-active={mode === "recipes"} onClick={() => this.setMode('recipes')}>Recipes</button>
                </li>
            );
        }

        if (meal_kit_providers?.length && !["Breakfast", "Snack"].includes(mealType) && !isPro && !inhibit_add_swap_modes?.includes('smart-choices::meal-kits')) {
            modePicker.push(
                <li key="meal-kits">
                    <button key="meal-kits" data-active={mode === "meal-kits"} onClick={() => this.setMode('meal-kits')}>Meal Kits</button>
                </li>
            );
        }

        if(!inhibit_add_swap_modes?.includes('smart-choices::ready-made')) {
            modePicker.push(
                <li key="ready-made-meals">
                    <button key="ready-made-meals" data-active={mode === "ready-made-meals"} onClick={() => this.setMode('ready-made-meals')}>Ready Made Meals</button>
                </li>
            );
        }

        if (modePicker.length <= 1) {
            return;
        }

        return <ul className="mode-picker">{modePicker}</ul>
    }


    onChangePostalCode =  (ev) => {
        const postalCode = ev.target.value;
        this.setState({postal_code: postalCode});
    }

    onSavePostalCode = async() => {
        const { postal_code, mode } = this.state;
        UserActions.updateSpecificMeta({postal_code});

        if (!postal_code.length) {
            this.setState({capturePostalCodeAlert: 'Please enter your postal code'});
            return;
        }

        this.setState({capturePostalCodeWorking: true});

        const response = await AuthStore.fetch({url: getConfig('recipe_api') + '/providers?postal_code=' + (postal_code || '')});
        const sunbasket = response?.elements.find(pv => pv.provider_key === 'sunbasket' && pv.available);

        if (!sunbasket) {
            this.setState({
                capturePostalCodeWorking: false,
                capturePostalCodeAlert: 'Very sorry but Sunbasket delivery is not available for your postal code.'
            });

            return;
        }

        this.setMode(mode)
    }

    closeModal = () => {
        const {  preferences } = this.state;

        preferences.meal_kit_providers = [];

        UserActions.updateSpecificMeta({preferences});

        this.setState({isModalOpen: false}, ()=> this.setMode('recipes'))
    }

    renderZipCodeModal = () => {
        const { isModalOpen, postal_code, capturePostalCodeWorking, capturePostalCodeAlert } = this.state;

        if (!isModalOpen) {
            return null;
        }

        return(
            <PostalCodeSelector isModalOpen
                postalCode={postal_code}
                working={capturePostalCodeWorking}
                closeModal={this.closeModal}
                onSavePostalCode={this.onSavePostalCode}
                alertMsg={capturePostalCodeAlert}
                modalTitle="Confirm Delivery Postal Code"
                saveButtonText="SUBMIT POSTAL CODE"
                onChangePostalCode={this.onChangePostalCode}>

                <p className="p3">To receive personalized recommendations for ready-made meals and meal kits, please enter your postal code below.</p>
            </PostalCodeSelector>

        )
    }


    render() {
        const { searchTermsProp, searchTermsPlaceholder } = this.props;
        const { params, fallbacks, loading, total, fallbackTotal, loadingMore, results, resultsParams,
                fallbackParams, dirty, meal_kit_providers, mode, postal_code } = this.state;
        const { isPro, addSwapContext, profile } = this.context;
        const { mealType } = addSwapContext;

        const { inhibit_add_swap_modes = [] } = profile?.preferences || {};

        const breakfastSnackRmmTotalFailureMessage = isPro && profile.first_name ?
                <span><strong>Ready-Made Coming Soon! </strong>{`We’re sorry, ready-made meals matching ${profile.first_name}'s ${mealType.toLowerCase()} prescription are not yet available. We'll notify you once they become available.`}</span>
                : <span><strong>Ready-Made Coming Soon! </strong>{`We’re sorry, ready-made meals matching your ${mealType.toLowerCase()} prescription are not yet available. We'll notify you once they become available.`}</span>

        const isTotalFailure = (dirty && !loading && total === 0 && fallbackTotal === 0);

        const totalFailureMessages = {
            "recipes": <span>Sorry, we couldn't find anything that fits.</span>,
            "ready-made-meals": isPro && profile.first_name ?
                <span><strong>Ready-Made Not Available: </strong>{`Ready-made meals matching ${profile.first_name}'s ${mealType.toLowerCase()} nutrition prescription could not be found.`}</span>
                : <span><strong>Ready-Made Not Available: </strong>{`Ready-made meals matching your ${mealType.toLowerCase()} nutrition prescription could not be found.`}</span>,
            "meal-kits": isPro && profile.first_name ?
                <span><strong>Meal Kits Not Available: </strong>{`Meal Kits matching ${profile.first_name}'s ${mealType.toLowerCase()} nutrition prescription are not available for this particular day. Availability is dependent upon meal kit provider menu schedules and delivery options.`}</span>
                : <span><strong>Meal Kits Not Available: </strong>{`Meal Kits matching your ${mealType.toLowerCase()} nutrition prescription are not available for this particular day. Availability is dependent upon meal kit provider menu schedules and delivery options.`}</span>
        }

        return (
            <div className="smart-choices-picker">
                <header data-dirty={dirty}>
                    <SearchKeywords params={params}
                        termsProp={searchTermsProp}
                        className="recipe-keywords-search ingredient-search"
                        onChangeParams={this.onChangeParams}
                        placeholder={searchTermsPlaceholder} />

                    {dirty && !loading ? <>
                        {!fallbacks?.length
                            ? <h3>Select from these <strong>Smart Choices</strong> that fit your nutrition requirements.</h3>
                            : <Alert className="alert-smart-choices" type="question" iconType="icon-warning5"
                                description={<><strong>A perfect match couldn't be found</strong>, but here are some options that are close enough.</>}
                                hintDescription={<p>Our Lena Nutrition Intelligence technology looks at 4 million data points to offer up meal choices that fit your nutrition prescription in the most perfect way, but sometimes even Lena can't find a perfect match when considering other foods you have scheduled for the day. The meals shown bring you close enough to meeting the requirements of your nutrition prescription. You may see warning signs appear when these meals are scheduled. Hovering over the warning sign will show you nutrient details to keep you informed.</p>} />
                        }
                    </> : null}

                    {!dirty ?
                        <h3>Enter up to 3 ingredients<br />
                        Eatlove will tell you what to make with them!</h3>
                    : null}

                    {!loading && (!["Breakfast", "Snack"].includes(mealType) || !isPro) ?
                        this.renderModeSelectorButtons()
                    : null}
                </header>

                {loading ?
                    <div className="smart-choices-picker-loading">
                        <h2>Loading...</h2>
                        <i className="icon-spinner" />
                    </div>
                : null}

                {dirty && !isTotalFailure && !loading ?
                    <div className="results-list">
                        {results.map(this.renderResult)}
                        {fallbacks.map(this.renderResult)}
                    </div>
                : null}

                {isTotalFailure ?
                    <div className="smart-choices-question">
                        <Alert type="question"
                            iconType="no-icon"
                            description={<>{["Breakfast", "Snack"].includes(mealType) && mode == "ready-made-meals" ? breakfastSnackRmmTotalFailureMessage : totalFailureMessages[mode]}</>}
                            hintDescription={<p>Our Lena Nutrition Intelligence technology looks at 4 million data points to offer up meal choices that fit your nutrition prescription in the most perfect way, but sometimes even Lena can't find a perfect match when considering other foods you have scheduled for the day. The meals shown bring you close enough to meeting the requirements of your nutrition prescription. You may see warning signs appear when these meals are scheduled. Hovering over the warning sign will show you nutrient details to keep you informed.</p>}
                        />
                    </div>
                : null}

                <footer>
                    {total > 0 ?
                        <Paginator loading={loading}
                            loadingMore={loadingMore}
                            total={total}
                            results={results}
                            resultsParams={resultsParams}
                            showMore={this.showMore}
                            buttonText={loadingMore || loading ? "Loading..." : "Load More Smart Choices"} />
                    : null}

                    {fallbackTotal > 0 ?
                        <Paginator loading={loading}
                            loadingMore={loadingMore}
                            total={fallbackTotal}
                            results={fallbacks}
                            resultsParams={fallbackParams}
                            showMore={this.showMoreFallbacks}
                            buttonText={loadingMore || loading ? "Loading..." : "Load More Smart Choices"} />
                    : null}

                    {params.terms && !loading && !inhibit_add_swap_modes.includes('browser') ?
                        <button className="sub-action-btn" onClick={this.switchToFullbrowser}>
                            Not finding what you’re looking for in Smart Choices? <em>Search all recipes</em>
                        </button>
                    : null}

                    {!loading && results.length === total && fallbacks.length === fallbackTotal && !params.terms && !inhibit_add_swap_modes.includes('browser') ?
                        <button className="sub-action-btn" onClick={this.switchToFullbrowser}>
                            Not finding what you’re looking for in Smart Choices? <em>Search all recipes</em>
                        </button>
                    : null}
                </footer>
                {this.renderZipCodeModal()}
            </div>
        );
    }
}
