'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import debounce from 'lodash.debounce';

import './Combobox.scss';

/**
 * The built-in browser drop-downs are inconsistent pieces of crap.
 *
 * This class replaces and abstracts them where necessary. It can be
 * styled and controlled much more easily, and I think it very closely
 * matches the good behaviors of the built-in, without some of its nastier
 * downsides.
 */
export default class Combobox extends Component {
    static propTypes = {
        button: PropTypes.node,
        children: PropTypes.node,
        tabIndex: PropTypes.number,
        options: PropTypes.array.isRequired,
        showSearch: PropTypes.bool,
        showAbove: PropTypes.bool,
        dropdownIcon: PropTypes.string,
        disabled: PropTypes.bool,
        defaultValue: PropTypes.string,
        defaultClassName: PropTypes.string,
    };

    static defaultProps = {
        showSearch: false,
        showAbove: false,
        disabled: false,
        dropdownIcon: "icon-arrow",
        defaultValue: '',
        defaultClassName: 'combobox-container'
    };

    static contextTypes = {
        isMobile: PropTypes.bool.isRequired,
        router: PropTypes.object,
    };

    static childContextTypes = {
        toggleMenu: PropTypes.func,
        closeMenu: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            isDropdownVisible: false,
            isFocused: false,
            typeAhead: props.defaultValue,

            search: '',
        };
    }

    getChildContext = () => {
        return {
            toggleMenu: this.toggleDropdown,
            closeMenu: this.closeDropdown,
        };
    }


    componentDidMount = () => {
        const { openByDefault } = this.props;

        if (openByDefault) {
            this.openDropdown();
        }
    }

    onOuterAction = (ev) => {
        if (!this.container || !this.dropdown) {
            return;
        }

        const isOuterAction  = (
            !this.container.contains(ev.target) &&
            !this.dropdown.contains(ev.target)
        );

        if (isOuterAction) {
            this.closeDropdown();
        }
    }

    closeDropdown = () => {
        this.setState({isDropdownVisible: false});

        if (!process.browser) {
            return;
        }

        window.removeEventListener('mousedown', this.onOuterAction);
        window.removeEventListener('touchstart', this.onOuterAction);
        window.removeEventListener('keyup', this.onOuterAction);
    }

    openDropdown = () => {
        this.setState({isDropdownVisible: true}, () => {
            this.scrollOptionIntoView(this.props.value);
        });

        if (!process.browser) {
            return;
        }

        window.addEventListener('mousedown', this.onOuterAction);
        window.addEventListener('touchstart', this.onOuterAction);
        window.addEventListener('keyup', this.onOuterAction);
    }

    toggleDropdown = () => {
        const { isDropdownVisible } = this.state;

        this.setState({isDropdownVisible: !isDropdownVisible, search: ''}, () => {
            if (!isDropdownVisible) {
                this.scrollOptionIntoView(this.props.value);
            }
        });

        if (!process.browser) {
            return;
        }

        // Are we transitioning from hidden to visible? Start listening to touch events everywhere else.
        if (!isDropdownVisible) {
            window.addEventListener('mousedown', this.onOuterAction);
            window.addEventListener('touchstart', this.onOuterAction);
            window.addEventListener('keyup', this.onOuterAction);

            this.refs.input.focus();
        } else {
            window.removeEventListener('mousedown', this.onOuterAction);
            window.removeEventListener('touchstart', this.onOuterAction);
            window.removeEventListener('keyup', this.onOuterAction);
        }
    }

    container = null;
    dropdown = null;
    scrollable = null;

    realizeContainer = (container) => {
        this.container = container;
    }

    realizeDropdown = (dropdown) => {
        this.dropdown = dropdown;
    }

    realizeScrollable = (scrollable) => {
        this.scrollable = scrollable;
    }

    onFocus = () => {
        this.setState({isFocused: true});
    }

    onBlur = (ev) => {
        this.setState({isFocused: false});
    }

    scrollOptionIntoView = (value) => {
        const { scrollable, dropdown } = this;
        const { options } = this.props;

        if (!process.browser || !scrollable || !dropdown || !options) {
            return;
        }

        const $scrollable = $(scrollable);
        const $dropdown = $(dropdown);
        const index = options.indexOf(options.find(o => o.value === value));

        if (index === -1) {
            return;
        }

        // Find the currently selected elements element.
        const $child = $scrollable.find(`[data-index="${index}"]`);

        // Element value not found
        if (!$child.length) {
            return;
        }

        const currentScrollTop = dropdown.scrollTop,
            viewportHeight     = $dropdown.innerHeight(),
            scrollerHeight     = $scrollable.innerHeight(),
            itemHeight         = $child.innerHeight(),
            dropdownTop        = $dropdown.offset().top,
            itemTop            = $child.offset().top - dropdownTop + currentScrollTop;

        // Make sure that we're scrolled into view. Scroll into view if not.
        if (itemTop + itemHeight > (currentScrollTop + viewportHeight)) {
            dropdown.scrollTop = (itemTop + itemHeight + 5) - viewportHeight;
        } else if (itemTop < currentScrollTop) {
            dropdown.scrollTop = itemTop;
        }

        // Otherwise, the element is currently visible through the viewport, no action is needed.
    }

    onChangeOption = (value, select = false) => {
        const { onChangeOption, onSelectOption } = this.props;

        this.openDropdown();

        if (select && onSelectOption) {
            onSelectOption(value);
            return;
        }

        onChangeOption && onChangeOption(value);
    }

    onChangeTypeAhead = (ev) => {
        const { value, options, onChangeTerms } = this.props;
        const typeAhead = ev.target.value;

        onChangeTerms && onChangeTerms(typeAhead);
        this.setState({typeAhead}, this.openDropdown);
    }

    setTypeAhead = (typeAhead) => {
        this.setState({typeAhead});
    }

    clearTypeAhead = () => {
        this.setState({typeAhead: ''});
    }

    onKeyUp = (ev) => {
        const { options, value } = this.props;

        // What index are we at now?
        let option = options.filter(o => o.value === value)[0];
        let index = options.indexOf(option);

        if (['ArrowDown', 'ArrowUp'].includes(ev.key)) {
            if (ev.key === 'ArrowDown') {
                index++;
            }

            if (ev.key === 'ArrowUp') {
                index--;
            }

            if (options[index] && options[index].value !== value) {
                this.onChangeOption(options[index].value);
            }
        }

        if (ev.key === 'Enter' && option) {
            this.onChangeOption(option.value, true);
            this.closeDropdown();
        }
    }

    onClickOption = (option) => {
        this.setState({typeAhead: option}, () => {
            const { onSelectOption } = this.props;
            onSelectOption && onSelectOption(option.value);
        });

        this.closeDropdown();
    }

    onClearCombobox = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const { onClearCombobox } = this.props;

        onClearCombobox && onClearCombobox();
    }

    render() {
        const { isMobile, router } = this.context;
        const { typeAhead, isDropdownVisible, isFocused, search } = this.state;
        const { value, options, className, button, children, placeholder, tabIndex, prefix, disabled,
                showSearch, showAbove, dropdownIcon, defaultClassName, onClearCombobox } = this.props;

        const option = options.filter(o => o.value == value)[0];
        const label = (option && option.label) || '';

        return (
            <span className={[defaultClassName, className].filter(v => v).join(' ')}
                data-disabled={disabled}
                data-state={isDropdownVisible && (options.length > 0 || children) ? true : false}
                data-focus={isFocused}
                data-error={this.props['data-error']}
                data-above={showAbove}
                data-show-x={!!onClearCombobox}
                ref={this.realizeContainer}>

                <input type="text" className="type-ahead" ref="input"
                    tabIndex={tabIndex}
                    value={typeAhead}
                    disabled={disabled}
                    placeholder={placeholder}
                    onKeyUp={this.onKeyUp}
                    onChange={this.onChangeTypeAhead}
                    onClick={this.toggleDropdown}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur} />

                {onClearCombobox ?
                    <button className="clear-name-btn" onClick={this.onClearCombobox}>
                        <i className="icon-x" />
                    </button>
                : null}

                {!onClearCombobox ?
                    <button className="chevron" onClick={disabled ? null : this.toggleDropdown} tabIndex="-1">
                        <i className={dropdownIcon} />
                    </button>
                : null}

                <div className="combobox-dropdown" ref={this.realizeDropdown}>
                    <div className="dropdown-content">

                        {children}

                        <ul ref={this.realizeScrollable}>
                            {options.map((opt, i) => {
                                return (
                                    <li className="option" key={i}
                                        onClick={() => !opt.disabled && this.onClickOption(opt)}
                                        data-index={i}
                                        data-disabled={opt.disabled}
                                        data-highlighted={opt.highlight}
                                        data-selected={opt.value == value}>
                                        {opt.label}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </span>
        );
    }
}
