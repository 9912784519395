"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import DocumentTypeParams from "./Params/DocumentType.react";
import ProfileParams from "./Params/ProfileParams.react";
import SortParams from "./Params/Sort.react";
import TermsParams from "./Params/Terms.react";
import SkillLevelParams from "./Params/SkillLevel.react";
import TagParams from "./Params/Tags.react";
import PlanSizeParams from "./Params/PlanSize.react";
import AvoidanceParams from "./Params/Avoidances.react";
import MerchantParams from "./Params/Merchant.react";
import MyLibraryParams from "./Params/MyLibrary.react";
import MyPrescriptionParams from "./Params/MyPrescription.react";
import CalorieLimits from "./Params/Calories.react";
import NutritionLimits from "./Params/NutritionLimits.react";
import LeftoversEnabled from "./Params/LeftoversEnabled.react";
import IngredientsParams from "./Params/Ingredients.react";
import TotalTimeParams from "./Params/TotalTime.react";
import CostPerServingParams from "./Params/CostPerServing.react";
import PrepTimeParams from "./Params/PrepTime.react";
import ClearFilters from "./Params/ClearFilters.react";
import Dropdown from "./Dropdown.react";

import UserStore from "../../stores/UserStore";
import AuthStore from '../../stores/AuthStore';
import { getConfig } from "../../utils/Env";

import { getDietsFromTags } from "../../utils/Diets";

import "./Params.scss";

export default class Params extends Component {
    static contextTypes = {
        showCreateCustom: PropTypes.func,
        showCreateRecipe: PropTypes.func,
        createCustomButtonCopy: PropTypes.node,
    };

    static propTypes = {
        total: PropTypes.number,
        loading: PropTypes.bool,
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
        onShowAdvancedFilters: PropTypes.func,
    };

    constructor(props) {
        super(props);

    }

    createCustomMeal = () => {
        const { showCreateCustom } = this.context;
        const { params } = this.props;

        showCreateCustom({ defaultCustomTitle: params.terms, defaultCustomType: "meal" });
    };

    createCustomRestaurant = () => {
        const { showCreateCustom } = this.context;
        const { params } = this.props;

        showCreateCustom({ defaultCustomTitle: params.terms, defaultCustomType: "restaurant" });
    };

    createCustomRecipe = () => {
        const { showCreateRecipe } = this.context;
        const { params } = this.props;

        showCreateRecipe({ defaultCustomTitle: params.terms });
    };


    render() {
        const { showCreateCustom, createCustomButtonCopy } = this.context;
        const { total, loading, params, onChangeParams, allowedTypes, hideLibraryFilter, showTypePicker } = this.props;
        const { hide_nutrition = false } = UserStore.getPreferences();
        
        return (
            <div className="global-search-results-params">
                <h2 data-total={total}>
                    {total} {total > 1 ? "RESULTS" : "RESULT"}
                </h2>

                {showTypePicker ? (
                    <DocumentTypeParams params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                ) : null}
                {!hideLibraryFilter ? <MyLibraryParams params={params} onChangeParams={onChangeParams} /> : null}
                <ProfileParams params={params} onChangeParams={onChangeParams} /> 
                <MyPrescriptionParams params={params} onChangeParams={onChangeParams} />
                <PlanSizeParams params={params} onChangeParams={onChangeParams} />
                <SkillLevelParams params={params} onChangeParams={onChangeParams} />
                <TagParams params={params} onChangeParams={onChangeParams} />
                <AvoidanceParams params={params} onChangeParams={onChangeParams} />
                <MerchantParams params={params} onChangeParams={onChangeParams} />
                {!hide_nutrition ? <CalorieLimits params={params} onChangeParams={onChangeParams} /> : null}
                {!hide_nutrition ? <NutritionLimits params={params} onChangeParams={onChangeParams} /> : null}
                <LeftoversEnabled params={params} onChangeParams={onChangeParams} />
                <IngredientsParams params={params} onChangeParams={onChangeParams} />
                <TotalTimeParams params={params} onChangeParams={onChangeParams} />
                <CostPerServingParams params={params} onChangeParams={onChangeParams} />
                <PrepTimeParams params={params} onChangeParams={onChangeParams} />
                <ClearFilters params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} includeLibrary={!hideLibraryFilter} />
                {showCreateCustom && total > 0 && allowedTypes.includes("custom") ? (
                    <div className="create-custom-dropdown">
                        <Dropdown button="CREATE CUSTOM">
                            <ul className="create-custom-btns">
                                <li>
                                    <button onClick={this.createCustomMeal}>Create custom food</button>
                                </li>
                                <li>
                                    <button onClick={this.createCustomRecipe}>Create recipe</button>
                                </li>
                            </ul>
                        </Dropdown>
                    </div>
                ) : null}

                {showCreateCustom && total > 0 && createCustomButtonCopy ? (
                    <button className="create-custom-btn" onClick={showCreateCustom}>
                        {createCustomButtonCopy}
                    </button>
                ) : null}
            </div>
        );
    }
}
